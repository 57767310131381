

import { memo, useRef,Fragment,useState } from "react";
import Layout from "./Layout";
import LayoutContent from "./LayoutContent";
import {
    Card,
    Grid,
    styled,
    useTheme,
    CardActions,
    CardContent,
    CardMedia,
    Button,
    Typography,
    Paper,
    Box
  } from "@mui/material";
import CourseLayout from "./CourseLayout";
import CurriculumLayout from "./CurriculumLayout"
import Footer from "./Footer";
import JobTraining from "./JobTrainingLayout";
const BodyContent = styled(Box)(({ theme }) => ({
    marginTop: "120px", // Space for all headers combined (adjust as needed)
    overflowY: "auto",
    height: "calc(100vh - 120px)", // Remaining height for content (adjust as needed)
    //padding: "1px", // Optional padding for content
  }));
const MainLayout = () => {
    const coursesRef = useRef(null);
    const curriculumRef = useRef(null);
    const jobTrainingRef = useRef(null);

    const [navData, setNavData] = useState('');
    const [cartData, setCartData] = useState();
    return (
        <Box  sx={{
            overflow: "hidden", // Prevent outer scroll
            height: "100vh", // Ensure the height covers the viewport
        // backgroundImage:   'url("/LandingPageBackground.png")',
           // width:'100%'
          }}>

<Layout fixed={true} curriculumRef={curriculumRef} coursesRef={coursesRef} jobTrainingRef={jobTrainingRef} navData={navData} cartData ={cartData}/>
            <BodyContent>
           
           
                    <LayoutContent/>
                    <div  ref={coursesRef}>
                    <CourseLayout setNavData={setNavData} setCartData={setCartData}/>
                    </div>
                    <div  ref={jobTrainingRef}>
                    <JobTraining setNavData={setNavData} setCartData={setCartData} ref={jobTrainingRef}/>
                    </div>
                    <span ref={curriculumRef}>
                    <CurriculumLayout />
                    </span>
                    <Footer/>
            </BodyContent>
           
        </Box>
    )
};

export default memo(MainLayout);
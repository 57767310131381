// import * as React from 'react';
// import { useState, useRef, useEffect } from 'react';
// import { 
//     Grid,
//     Typography,
//     TextField,
//     IconButton,
//     InputAdornment,
//     Snackbar
// } from '@mui/material';
// import { Button } from 'semantic-ui-react'
// import { useSelector, useDispatch } from 'react-redux'
// import { ForgotPasswordOtpFunction,ForgotPasswordOtpConfirmFunction,ForgetPasswordChangePasswordFunction } from '../../Redux/slices/UserSlice/UserAPI';
// import  SpringBoardLogo  from '../../assets/SpringBoardIncubatorsLogo.png';
// // import  SpringBoardSmallLogo  from '../../assets/SbSmallLogo.png'
// import Visibility from '@mui/icons-material/Visibility';
// import VisibilityOff from '@mui/icons-material/VisibilityOff';
// import { useNavigate } from 'react-router-dom';

// const MainContainer = {
//   display: 'flex',
//   justifyContent: 'center',
//   alignItems: 'center',
//   height: '100vh', // Ensure it takes full viewport height  
// }


// const SignUpOuterContainer = {
//   boxShadow: '5px 10px 18px #888888',
//   maxWidth: '60%',
//   height: '90vh',
//   borderRadius: '3rem'
// }

// const ImageContainer = {
//   backgroundColor: '#9BEDCC',
//   borderRadius: '0 3rem 3rem 0'
// }

// const ImageInnerContainer = {
//   display: 'flex',
//   flexDirection: 'column',
//   justifyContent: 'center',
//   alignItems: 'center',
//   // display: 'flex', 
//   // flexDirection: 'column',
//   // justifyContent: 'center',
//   // alignItems: 'center',
//   // height: '100%',  // Ensure the container takes up the full height of its parent

// }

// const FormContainer = {
//   display: 'flex',
//   // flexDirection: 'column',
//   justifyContent: 'center',
//   alignItems: 'center',
// }

// const HeaderTextStyle = {
//  textAlign: 'center',
//  marginBottom: '1rem',
//  fontSize: '1.3rem' 
// }

// const InnerFormContainer = {
 
// }

// const TextfieldStyle = {
//   width: '120%',
//   height: '3.5rem',
//   marginBottom: '1rem'
// }

// const DropdownStyle = {
//   width: '120%',
//   marginBottom: '1rem'   
// }

// const SignUpButtonStyle = {
//  width: '120%',
//  textTransform: 'none'
// }
   
// const TextStyle = {
//   fontSize: '1.1rem',
//   fontWeight: '500',
//   marginTop: '0.7rem'
// }

// const SpringBoardLogoStyle = {
//   width: '17rem',
//   height: '5rem',
// }

// const SpringBoardSmallLogoStyle = {
//   width: '5rem',
//   height: '5rem',
//   marginTop: '0.7rem'
// }
// const OtherLinkContainer = {
//   display: 'flex',
//   flexDirection: 'column',
//   justifyContent: 'center',
//   alignItems: 'center', 
// }
// const SignUpContainer = {
//   display: 'flex',
//   flexDirection: 'row',
//   justifyContent: 'center',
//   alignItems: 'center',  
//   marginTop: '2rem'
// }
// const SignUpTextStyle = {
//   paddingLeft: '2%',
//   color: '#1578C1',
//   cursor: 'pointer',  // Changes cursor to pointer
//   '&:hover': {
//     cursor: 'pointer'  // Ensures pointer on hover
//   }
// }
// const GoogleSignUpContainer = {
//   display: 'flex',
//   flexDirection: 'row',
//   justifyContent: 'center',
//   alignItems: 'center',  
//   marginTop: '2rem',
//   padding: '0.7rem 0.7rem 0.7rem 0.7rem',
//   boxShadow: '4px 6px 12px #888888',
//   borderRadius: '0.5rem',
//   cursor: 'pointer',  // Changes cursor to pointer
//   '&:hover': {
//     cursor: 'pointer'  // Ensures pointer on hover
//   }
// }

// const ForgetPasswordPage = () => {
  
//  const dispatch = useDispatch()
//  const navigate = useNavigate();

//  const [email, setEmailid] = useState('');
//  const [otp, setOtp] = useState('');
//  const [password,setPassword]=useState('');
//  const [confirmPassword, setConfirmPassword] = useState('');
//  const [open, setOpen] = useState(false);
//  const [openInitialState,setOpenInitialState] =useState(true)
//  const [openOtpField, setOpenOtpField] = useState(false);
//  const [openPasswordAdnConfirmPasswordField, setPasswordAndConfirmPasswordField] = useState(false);

//  const [ErrorFlagEmail, setErrorFlagEmailid] = useState(false);
//  const [ErrorFlagOtp, setErrorFlagOtp] = useState(false);
//  const [ErrorFlagPassword, setErrorFlagPassword] = useState(false);
//  const [ErrorMessagePassword, setErrorMessagePassword] = useState('Password required');
//  const [ErrorMessageConfirmPassword, setErrorMessageConfirmPassword] = useState('Confirm Password required');
//  const [ErrorFlagConfirmPassword, setErrorFlagConfirmPassword] = useState(false);
//  const [showPassword, setShowPassword] = React.useState(false);
//  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);

//  const handleClickShowPassword = () => setShowPassword((show) => !show);
//  const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);

//  let message = useSelector(state => state.User?.message);
//  let success = useSelector(state => state.User?.success);
//   let otpSendApiSuccess = useSelector(state => state.User?.otpSend);
//   let otpConfirmApiSuccess = useSelector(state => state.User?.otpConfirm);
//   let passwordConfirmApiSuccess = useSelector(state => state.User?.passwordConfirm)
//   let tempToken = useSelector(state=>state.User?.token)
// console.log('message in forgot passowrd page',message)

// useEffect(() => {
//   console.log('in use effect of success.....')
//   console.log('successsss... before',typeof success)
//   console.log('otpConfirmApiSuccess', otpConfirmApiSuccess)
  
//   if (success == "true" && otpSendApiSuccess) {
//     console.log('successsss',success)
//     setOpenOtpField(true)
//   }

//   if(success == "true" && otpConfirmApiSuccess==='true'){
//     console.log('in if of true success and otpconfirm api success')
//     setOpenInitialState(false)
//     setOpenOtpField(false)
//     setPasswordAndConfirmPasswordField(true)
//   }
//   if(success == true && passwordConfirmApiSuccess){
//     setOpenInitialState(true);
//     setPasswordAndConfirmPasswordField(false)
//     setEmailid('')
//   }
// }, [success, navigate]);

//  const handleClick = () => {


//   if(email == ''){
//     setErrorFlagEmailid(true)
    
//     return;
//   } 

  

//   setOpen(true);


//   dispatch(
//     ForgotPasswordOtpFunction({
//       email,   
//     })
//   )
//  };
// const handleClickOfConfirmOtp = () =>{

//   if(openOtpField && otp == ''){
//     setErrorFlagOtp(true)
//   }
//   setOpen(true);
//   console.log('temptoken',tempToken)
//   dispatch(
//     ForgotPasswordOtpConfirmFunction({
//       email,otp,tempToken
//     })
//   )
// }

// const handleClickOfChangePassoword =()=>{
//   if(openPasswordAdnConfirmPasswordField && password ==''){
//     setErrorFlagPassword(true)
//   }
//   if(openPasswordAdnConfirmPasswordField && confirmPassword==''){
//     setErrorFlagConfirmPassword(true)
    
//   }
//   setOpen(true);
//   dispatch(
//     ForgetPasswordChangePasswordFunction({
//       email, password, confirmPassword
//     })
//   )
// }

//  const setField = (FieldName,value) => {

//   if(FieldName == 'Emailid'){
//     setEmailid(value)
//     setErrorFlagEmailid(false)
//   } 

//   if(FieldName == 'Otp'){
//     setOtp(value)
//     setErrorFlagOtp(false)
//   } 

//   if(FieldName == 'Password'){
//     setPassword(value)
//     setErrorFlagPassword(false)

//   } 
//   if(FieldName == 'ConfirmPassword'){
//     setConfirmPassword(value)
//     setErrorFlagConfirmPassword(false)

//   } 
//  }
//  const handleSignUpNavigation = () => {
//   navigate('/signin');
//  }
//  return (
//   <>
//    <Grid container style={MainContainer} xs={12} sm={12} md={12}>
//     <Grid container style={SignUpOuterContainer} xs={12} sm={12} md={12}>
//      <Grid item style={FormContainer} xs={12} sm={6} md={6}>
//       <Grid item style={InnerFormContainer} xs={12} sm={6} md={6}>
//        <Typography sx={HeaderTextStyle}><b>Forgot Password</b></Typography>
//        <br></br>
//       {openInitialState && <TextField 
//         id="outlined-basic" 
//         label="Email id" 
//         variant="outlined" 
//         sx={{
//           ...TextfieldStyle,
//           ...(ErrorFlagEmail && { // Conditional styles based on ErrorFlagEmail
//            marginBottom: '1.9rem'
//           }),
//         }}
//         error={ErrorFlagEmail}
//         value={email} 
//         onChange={(event) => setField('Emailid',event.target.value)}
//         helperText={ErrorFlagEmail ? "Email id required" : ""}
       
//        />
//  } 
//        {openOtpField && <TextField 
//         id="outlined-basic" 
//         label="OTP" 
//         variant="outlined" 
//         sx={{
//           ...TextfieldStyle,
//           ...(ErrorFlagOtp && { // Conditional styles based on ErrorFlagEmail
//            marginBottom: '1.9rem'
//           }),
//         }}
//         error={ErrorFlagOtp}
//         value={otp} 
//         onChange={(event) => setField('Otp',event.target.value)}
//         helperText={ErrorFlagOtp ? "OTP id required" : ""}
       
//        />}
//        {
//         openPasswordAdnConfirmPasswordField &&  <TextField
//         label="Password"
//         type={showPassword ? 'text' : 'password'}
//         variant="outlined"
//         sx={TextfieldStyle} 
//         value={password} 
//         onChange={(event) => setField('Password',event.target.value)}
//         fullWidth
//         error={ErrorFlagPassword}
//         InputProps={{
//          endAdornment: (
//            <InputAdornment position="end">
//              <IconButton
//                onClick={handleClickShowPassword}
//                // onMouseDown={handleMouseDownPassword}
//                edge="end"
//              >
//                {showPassword ? <VisibilityOff /> : <Visibility />}
//              </IconButton>
//            </InputAdornment>
//           ),
//          }}
//         helperText={ErrorFlagPassword ? `${ErrorMessagePassword}` : ""}
//        />
//        }
//        {
//         openPasswordAdnConfirmPasswordField &&    <TextField
//         label="Confirm Password"
//         type={showConfirmPassword ? 'text' : 'password'}
//         variant="outlined"
//         error={ErrorFlagConfirmPassword}
//         sx={TextfieldStyle} 
//         value={confirmPassword} 
//         onChange={(event) => setField('ConfirmPassword',event.target.value)}
//         fullWidth
//         InputProps={{
//          endAdornment: (
//            <InputAdornment position="end">
//              <IconButton
//                onClick={handleClickShowConfirmPassword}
//                // onMouseDown={handleMouseDownPassword}
//                edge="end"
//              >
//                {showPassword ? <VisibilityOff /> : <Visibility />}
//              </IconButton>
//            </InputAdornment>
//           ),
//          }}
//          helperText={ErrorFlagConfirmPassword ? `${ErrorMessageConfirmPassword}` : ""}
//        />
 
//        } 
//         <br></br>
//         <br></br>
//        <Snackbar
//          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
//          open={open}
//          severity="warning"
//          autoHideDuration={1000}
//          message={message}
//         //  action={action}
//        />
//        {!openOtpField && !openPasswordAdnConfirmPasswordField && <Button sx={{  width: '120%',textTransform: 'none' }} onClick={handleClick} primary>Send OTP</Button>  }    
//        {
//          openOtpField && <Button sx={{  width: '120%',textTransform: 'none' }} onClick={handleClickOfConfirmOtp} primary>Confirm OTP</Button>      
//        }
//         {
//          openPasswordAdnConfirmPasswordField && <Button sx={{  width: '120%',textTransform: 'none' }} onClick={handleClickOfChangePassoword} primary>Submit</Button>      
//        }

//       <Grid container style={OtherLinkContainer} xs={12} sm={12} md={12}>
//         <Grid container style={SignUpContainer} xs={12} sm={12} md={12}>
//          <Typography>Already have an account?</Typography>
//          <Typography style={SignUpTextStyle} onClick={handleSignUpNavigation}>Signin</Typography>
//         </Grid>
       
//        </Grid>
//       </Grid>
//      </Grid>
//      <Grid container style={ImageContainer} xs={12} sm={6} md={6}>
//       <Grid item style={ImageInnerContainer} xs={12} sm={12} md={12}>
//         <img src={SpringBoardLogo} style={SpringBoardLogoStyle}/>
//         <Typography style={TextStyle}>Technology, Education & Opportunity</Typography>
//         {/* <img src={SpringBoardSmallLogo} style={SpringBoardSmallLogoStyle}/> */}
//       </Grid>
//      </Grid>
//     </Grid>
//    </Grid>
//   </>  
//  )   
// }

// export default ForgetPasswordPage;

import { useState,useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Card, Checkbox, Grid, TextField, Box, styled, useTheme ,Snackbar,Typography,MenuItem} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Formik } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch } from 'react-redux'
import { resetSuccess } from '../../Redux/slices/UserSlice/UserSlice';
import { ForgotPasswordOtpFunction,ForgotPasswordOtpConfirmFunction,ForgetPasswordChangePasswordFunction } from '../../Redux/slices/UserSlice/UserAPI';
import { Paragraph } from "../../Components/Typography";
import Layout from '../Layout/Layout';

// STYLED COMPONENTS
const BodyContent = styled(Box)(({ theme }) => ({
   
  marginTop: "30px", 
  overflowY: "auto",
 // height: "calc(100vh - 120px)", 
 
}));
const FlexBox = styled(Box)(() => ({
  display: "flex"
}));

const ContentBox = styled("div")(() => ({
  height: "100%",
  padding: "32px",
  position: "relative",
 // background: "rgba(0, 0, 0, 0.01)"
}));
const HeaderTextStyle = {
  textAlign: 'center',
  marginBottom: '3rem',
  fontSize: '1.3rem',
  marginTop:'17px',
  color:'black'
 }
const StyledRoot = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
 // backgroundColor: "#275317",
  minHeight: 812,
  //minHeight: "100% !important",
  "& .card": {
    maxWidth: 800,
   // minHeight: 400,
    margin: "1rem",
    display: "flex",
    borderRadius: 12,
    alignItems: "center"
  },

  ".img-wrapper": {
    height: "100%",
    minWidth: 320,
    display: "flex",
    padding: "1rem",
    alignItems: "center",
    justifyContent: "center"
  }
}));

const initialValues = {
 
  email: "",
  otp:"",
  password:"",
  confirmPassword:"",

  
};

const validationSchema = Yup.object().shape({
  
 
 
  email: Yup.string()
    .email("Invalid Email address")
    .required("Email is required!"),

  
});

export default function ForgetPassword() {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false); const [open, setOpen] = useState(false);
  const [openInitialState,setOpenInitialState] =useState(true)
  const [openOtpField, setOpenOtpField] = useState(false);
  const [openPasswordAdnConfirmPasswordField, setPasswordAndConfirmPasswordField] = useState(false);
  let message = useSelector(state => state.User?.message);
  let success = useSelector(state => state.User?.success);
   let otpSendApiSuccess = useSelector(state => state.User?.otpSend);
   let otpConfirmApiSuccess = useSelector(state => state.User?.otpConfirm);
   let passwordConfirmApiSuccess = useSelector(state => state.User?.passwordConfirm)
   let tempToken = useSelector(state=>state.User?.token)



  useEffect(() => {

    
    if (success == "true" && otpSendApiSuccess) {

      setOpenOtpField(true)
    }
  
    if(success == "true" && otpConfirmApiSuccess==='true'){
  
      setOpenInitialState(false)
      setOpenOtpField(false)
      setPasswordAndConfirmPasswordField(true)
    }
    if(success == true && passwordConfirmApiSuccess){
      setOpenInitialState(true);
      setPasswordAndConfirmPasswordField(false)
      initialValues.email=""
    }
  }, [success, navigate]);
  


  
  useEffect(() => {
    // Cleanup function to reset success when component unmounts
    return () => {
      dispatch(resetSuccess());
    };
  },[dispatch]);

    const handleClickOfConfirmOtp = async (values) => {
      let email = values.email;
      let otp = values.otp
    setOpen(true);
 
    dispatch(
      ForgotPasswordOtpConfirmFunction({
        email,otp,tempToken
      })
    )
  }
  const handleClickOfChangePassoword = async (values) => {
    let email = values.email;
    let password= values.password;
    let confirmPassword=values.confirmPassword
  
    dispatch(
      ForgetPasswordChangePasswordFunction({
        email, password, confirmPassword
      })
    )
  }

  const handleClick = async(values) => {
    setLoading(true);
   
    let email= values.email


  
  
    dispatch(
      ForgotPasswordOtpFunction({
        email,   
      })
    )
   };

  return (
    <Box  sx={{
      overflow: "hidden", 
      height: "100vh", 
    }}>
<Layout/>

<BodyContent>
<ContentBox>
    <StyledRoot>
      <Card  sx={{
        boxShadow: "5px 10px 18px #888888", 
        borderRadius: 3, 
       
      }}className="card">
        <Grid container>
          <Grid style={{backgroundColor:'#275317'}}item sm={6} xs={12}>
            <div className="img-wrapper">
              <img src="WhiteSpringBoardLogo.png" width="100%" alt="" />
            </div>
           
          </Grid>

          <Grid item sm={6} xs={12}>
         
            <ContentBox>
            <Typography sx={HeaderTextStyle}><b>FORGET PASSWORD</b></Typography>
          
            <Formik
                onSubmit={async (values) => {
                  if (!openOtpField && !openPasswordAdnConfirmPasswordField) {
                      await handleClick(values); // Send OTP
                  } else if (openOtpField) {
                 
                      await handleClickOfConfirmOtp(values); // Confirm OTP
                  }else if(openPasswordAdnConfirmPasswordField){
                    await handleClickOfChangePassoword(values)
                  }
              }}
                initialValues={initialValues}
                validationSchema={validationSchema}
              >
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                  <form onSubmit={handleSubmit}>
                     
                    
                     {openInitialState &&   <TextField
                      fullWidth
                      size="small"
                      type="email"
                      name="email"
                      label="Email"
                      variant="outlined"
                      onBlur={handleBlur}
                      value={values.email}
                      onChange={handleChange}
                      helperText={touched.email && errors.email}
                      error={Boolean(errors.email && touched.email)}
                      sx={{ mb: 3 }}
                    />}

                    {openOtpField &&  <TextField
                      fullWidth
                      size="small"
                      type="otp"
                      name="otp"
                      label="Otp"
                      variant="outlined"
                      onBlur={handleBlur}
                      value={values.otp}
                      onChange={handleChange}
                      helperText={touched.otp && errors.otp}
                      error={Boolean(errors.otp && touched.otp)}
                      sx={{ mb: 3 }}
                    />}
                 {
                  openPasswordAdnConfirmPasswordField &&[
                    <TextField
                      fullWidth
                      size="small"
                      name="password"
                      type="password"
                      label="Password"
                      variant="outlined"
                      onBlur={handleBlur}
                      value={values.password}
                      onChange={handleChange}
                      helperText={touched.password && errors.password}
                      error={Boolean(errors.password && touched.password)}
                      sx={{  mb: 3 }}
                    />,
                    <TextField
                    fullWidth
                    size="small"
                    name="confirmPassword"
                    type="password"
                    label="Confirm Password"
                    variant="outlined"
                    onBlur={handleBlur}
                    value={values.confirmPassword}
                    onChange={handleChange}
                    helperText={touched.confirmPassword && errors.confirmPassword}
                    error={Boolean(errors.confirmPassword && touched.confirmPassword)}
                    sx={{ mb: 3 }}
                  />

                  ]
                 } 

                    {!openOtpField && !openPasswordAdnConfirmPasswordField &&  <LoadingButton
                      type="submit"
                      //color="primary"
                      //loading={loading}
                      variant="contained"
                      style={{backgroundColor: "#275317"}}
                      sx={{ my: 2 }}
                    >
                      Send Otp
                    </LoadingButton>
                    }    
                        {
                          openOtpField && <LoadingButton
                          type="submit"
                          //color="primary"
                          //loading={loading}
                          variant="contained"
                          style={{backgroundColor: "#275317"}}
                          sx={{ my: 2 }}
                        >
                          Confirm Otp
                        </LoadingButton>

                        }
                          {
                          openPasswordAdnConfirmPasswordField &&  <LoadingButton
                          type="submit"
                          //color="primary"
                          //loading={loading}
                          variant="contained"
                          style={{backgroundColor: "#275317"}}
                          sx={{ my: 2 }}
                        >
                          Submit
                        </LoadingButton>

                        }
                   
                   <Paragraph>
                      Already have an account?
                      <NavLink
                        to="/signin"
                        style={{ color: theme.palette.primary.main, marginLeft: 5 }}
                      >
                        Login
                      </NavLink>
                    </Paragraph>
                  </form>
                )}
              </Formik>
              <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={open}
                severity="warning"
                autoHideDuration={2000}
                message={message}        
         //  action={action}
       />
            </ContentBox>
          </Grid>
        </Grid>
      </Card>
    </StyledRoot>
    </ContentBox>

</BodyContent>
</Box>
  );
}


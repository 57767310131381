import { Fragment } from "react";
import {
  Card,
  Grid,
  styled,
  useTheme,
  CardActions,
  CardContent,
  CardMedia,
  Button,
  Typography,
  Paper,
  Container,
  Box
} from "@mui/material";
import MemoryIcon from "@mui/icons-material/Memory";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import PeopleIcon from "@mui/icons-material/People";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
 import CourseLayout from "./CourseLayout";
const ContentBox = styled("div")(({ theme }) => ({
   margin: "85px",
  // margin: "7%",
  
  [theme.breakpoints.down("sm")]: { margin: "16px" }
}));

export default function LayoutContent() {
  const { palette } = useTheme();

  return (
    
      <ContentBox>
      <Container maxWidth="lg" sx={{ py: 1 }}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Typography variant="h4" gutterBottom fontWeight="bold">
            WELCOME TO SPRINGBOARD eLEARNING EDUCATION PLATFORM
          </Typography>
          <Typography variant="body1" paragraph>
          At Springboard, we believe that learning should be accessible, engaging, and transformative. Our platform offers a wide range of online courses and job training programs designed to help you unlock your potential and stay ahead in today’s fast-paced world. Whether you’re aiming to advance your career, acquire in-demand skills, or explore new fields, you can learn anytime, anywhere with our easy-to-use courses tailored to your goals. Join a community of learners and embark on your journey to success with Springboard Incubators today!
          </Typography>
          <Button variant="outlined" color="primary">
            LEARN MORE
          </Button>
        </Grid>
        <Grid item xs={12} md={6} container justifyContent="center" alignItems="center">
         
           <img style={{marginLeft:'40%',marginTop:'-12%'}}src="SbLandingPage.png"></img> 
        </Grid>

        <Grid item xs={12} md={6} container spacing={2}>
          <Grid item xs={6} style={{marginTop:'-20%'}}>
           
            <img src="landing.png"></img>
         
          </Grid>
         
        </Grid>
        
        <Grid item xs={12} md={4}>
          <Typography variant="h5" gutterBottom fontWeight="bold">
            TRY BEFORE YOU BUY
          </Typography>
          <Typography variant="body1" paragraph>
          Get a sneak peek at our courses and see the impact for yourself. Explore for free and experience the value before committing. Start your journey today!
          </Typography>
          <Button variant="outlined" color="primary">
            VIDEO DEMO
          </Button>
        </Grid>
        <Grid item xs={12} md={2}>
          
        </Grid>

      </Grid>
    </Container> 
  </ContentBox>
   
  );
}


import { useState, useEffect, useRef } from 'react';
import {
  Grid,
  Paper,
  Tabs,
  Tab,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Checkbox,
  Box
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import ReactPlayer from 'react-player';
import { useSelector,useDispatch } from 'react-redux';
import { styled } from '@mui/material/styles';
import { useLocation,useNavigate } from 'react-router-dom';
import NavigationBarPage from '../NavBar/NavigationBarPage';
import axios from 'axios';
import Layout from '../Layout/Layout';
import { CourseVideoProgress } from '../../Redux/slices/CourseSlice/CourseAPI';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} {...other}>
      {value === index && <Typography>{children}</Typography>}
    </div>
  );
}
const BodyContent = styled(Box)(({ theme }) => ({
  marginTop: "120px", 
  overflowY: "auto",
  height: "calc(100vh - 120px)", // Remaining height for content (adjust as needed)
 
}));
const ContentBox = styled("div")(({ theme }) => ({
  // margin: "21px",
  marginTop:'20px',
  [theme.breakpoints.down("sm")]: { margin: "16px" }
}));

const VideoStreamingPage = () => {
  const location = useLocation();
  const prevLocation = useRef(location); 
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [value, setValue] = useState('Course Content');
  const [url, setUrl] = useState('');
  let data = location.state == null ? [] : location.state.videoList;
  let courseId = location.state == null ? [] : location.state.courseId;
  const [selectedLecture, setSelectedLecture] = useState(null);
  const [sectionId, setSectionId] = useState(null);
  const [tabIndex, setTabIndex] = useState(0);
  let user_data = useSelector((state) => state.User?.user_data);
  const [isPlaying, setIsPlaying] = useState(false);
  const intervalRef = useRef(null);
  const [playedSeconds, setPlayedSeconds] = useState(0);
  const [duration, setDuration] = useState(0);
  const [expanded, setExpanded] = useState(false);
  // Initialize progress state
  const [progress, setProgress] = useState({});
  const [activeVideo, setActiveVideo] = useState(null); // Track the active video
  const [progressData, setProgressData] = useState({}); // Store progress data for all videos
  const playerRef = useRef(null);
  const hasSeekedRef = useRef(false); 

  
  useEffect(() => {
 
    fetchProgressData();
  }, [dispatch]);

  const fetchProgressData = async () => {
   
    try {
      const response = await dispatch(CourseVideoProgress({
        courseId
      }))
    
      const formattedProgressData = {};
      let backendData = response.payload.data.progress[0].progress

    Object.keys(backendData).forEach((sectionKey) => {
      const section = backendData[sectionKey];
      formattedProgressData[sectionKey] = {
        sectionId: section.sectionId,
        lectures: section.lectures.map((lecture) => ({
          lectureId: lecture.lectureId,
          timePlayed: lecture.timePlayed,
          watched: lecture.watched,
        })),
      };
    });
    
  setProgressData(formattedProgressData);
    } catch (error) {
      console.error('Error fetching progress data:', error);
    }
  };

  const handleReady = () => {
    if (activeVideo && !hasSeekedRef.current) {
      const { sectionId, lecture ,sectionKey} = activeVideo;
    
      // Access the section progress data
    
      const sectionProgress = progressData[sectionKey];
      if (!sectionProgress) return; // No progress for this section
  
      // Find the saved time for the specific lecture
      const lectureProgress = sectionProgress.lectures.find(l => {
      
        return l.lectureId === lecture._id; // Add 'return' here
      });
     
      const savedTime = lectureProgress ? lectureProgress.timePlayed : 0; // Default to 0 if not found
      
      if (playerRef.current) {
    
        playerRef.current.seekTo(savedTime, 'seconds');
        hasSeekedRef.current = true; // Set flag to prevent repeated seeking
      }
    }
  };
  const handleVideoClick = (sectionId, lecture,sectionKey,sectionDuration) => {
  
    setSelectedLecture(lecture._id);
    setActiveVideo({ sectionId, lecture,sectionKey,sectionDuration });

    hasSeekedRef.current = false;
    
  };
  const getLectureWatchedStatus = (sectionId, lectureId) => {
    return (
      progressData[sectionId]?.lectures?.[lectureId]?.watched || false
    );
  };
  const handleProgress = (state) => {
  
    if (activeVideo) {
      const { sectionId, lecture ,sectionKey,sectionDuration} = activeVideo;
      const sectionProgress = progressData[sectionKey] || {};
    
      // Update the time played for the lecture
      setProgressData({
        ...progressData,
        [sectionKey]: {
          sectionId,
          sectionDuration,
          ...sectionProgress,
          lectures: [
           
            ...(sectionProgress.lectures || []).filter((lec) => lec.lectureId !== lecture._id), // Keep other lectures
            {
              lectureId: lecture._id,
              timePlayed: state.playedSeconds,
              watched: state.playedSeconds >= lecture.duration * 0.9, // Mark as watched if 90% is completed
            },
          ],
        },
      });
    }
  };

  const handlePause = () => {
    saveProgressToDatabase(progressData); // Save to database on pause
  };
  const saveProgressToDatabase = async (progressData) => {
    try {
      const payload = {
        userId: user_data,
        courseId,
        progress: progressData,

      };
     
      const response =   await axios.post('https://app.sbelearning.com/api/saveProgress', payload,{
    
      headers: { 'Content-Type': 'application/json' },
        });
        console.log('response',response)
    } catch (error) {
      console.error('Error saving progress:', error);
    }
  };

  const convertToMinutes = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    if (hours > 0) {
      if (remainingSeconds === 0) {
        return `${hours} hr ${minutes} min`;
      } else if (minutes === 0) {
        return `${hours} hr `;
      } else {
        return `${hours} hr ${minutes} min ${remainingSeconds} sec`;
      }
    } else {
      if (remainingSeconds === 0) {
        return `${minutes} min`;
      } else {
        return `${minutes} min ${remainingSeconds} sec`;
      }
    }
  };
  return (
    <>
      <Box  sx={{
            overflow: "hidden", // Prevent outer scroll
            height: "100vh", // Ensure the height covers the viewport
          }}>
  <Layout />
  <BodyContent>
    <ContentBox>
    <Grid container>
      
      {/* Left Half */}
      <Grid item xs={12} md={8}>
      
      {/* {!url && <img style={{width:'100%',height:'60%'}}src = "sample.jpeg"></img> }  */}
       {/* {url && <ReactPlayer
          url={url}
          controls={true}
          width="100%"
          height="86%"
          playing={isPlaying}
          onProgress={handleProgress}
          progressInterval={1000}
          onDuration={handleDuration}
          onPlay={handlePlay}
          onPause={handlePause}
          onEnded={handleEnded}
          played={playedSeconds / duration}
        />} */}
           {!activeVideo && <img style={{width:'100%',height:'60%'}}src = "sample.jpeg"></img> } 
         {activeVideo && (
          <ReactPlayer
          ref={playerRef}
            url={activeVideo.lecture.url}
            controls
            width="100%"
            height="86%"
            playing={true}
           
            onProgress={handleProgress}
            onPause={handlePause}
            onReady={handleReady}
          />
        )}
      
     
      </Grid>
      
      {/* Right Half */}
      <Grid item xs={12} md={4}>
        {Object.entries(data).map(([sectionKey, section]) =>
          section && section._id ? (
            <Accordion key={section._id} style={{ margin: '0px' }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`${sectionKey}-content`}
                id={`${sectionKey}-header`}
                style={{ marginBottom: 0, padding: '0px 16px', backgroundColor: '#f7f9fa' }}
              >
                <div>
                  <Typography variant="h6">
                    <b>{`${sectionKey} : ${section.name}`}</b>
                  </Typography>
                  <Typography variant="h6">
                  {`${section.lectures.filter(lecture => getLectureWatchedStatus(section._id, lecture._id)).length} / ${section.lectures.length} | ${convertToMinutes(parseInt(section.duration))}`}
                   
                  </Typography>
                </div>
              </AccordionSummary>
      
              <AccordionDetails>
                <div>
                  {section.lectures &&
                    section.lectures.map((lecture) =>
                      lecture && lecture._id ? (
                        <div
                          key={lecture._id}
                          style={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            padding: '8px',
                            marginBottom: '8px',
                            borderRadius: '8px',
                            cursor: 'pointer',
                            transition: 'background-color 0.3s',
                            backgroundColor: selectedLecture === lecture._id ? 'whitesmoke' : '',
                          }}
                          onClick={() =>
                            handleVideoClick(section._id,lecture ,sectionKey,section.duration)
                          }
                          onMouseEnter={(e) =>
                            (e.currentTarget.style.backgroundColor =
                              selectedLecture === lecture._id ? 'whitesmoke' : 'whitesmoke')
                          }
                          onMouseLeave={(e) =>
                            (e.currentTarget.style.backgroundColor =
                              selectedLecture === lecture._id ? 'whitesmoke' : '')
                          }
                        >
                          <Checkbox
                            style={{ marginRight: '8px' }}
                            checked={getLectureWatchedStatus(section._id, lecture._id)}
                          />
                          <div
                            style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
                          >
                            <Typography variant="body1">
                              {`${lecture.lecture}: ${lecture.title}`}
                            </Typography>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                marginTop: '4px',
                              }}
                            >
                              <OndemandVideoIcon />
                              <Typography variant="body2" style={{ marginLeft: '8px' }}>
                                {`${convertToMinutes(parseInt(lecture.duration))}`}
                              </Typography>
                            </div>
                          </div>
                        </div>
                      ) : null
                    )}
                </div>
              </AccordionDetails>
            </Accordion>
          ) : null
        )}
      </Grid>
      </Grid>
    </ContentBox>
      </BodyContent>
      </Box>
    </>
  );
};

export default VideoStreamingPage;

import { Fragment } from "react";
import {
  Card,
  Grid,
  styled,
  useTheme,
  CardActions,
  CardContent,
  CardMedia,
  Button,
  Typography,
  Paper,
  Box,
  Divider,
  IconButton,
  ListItemText
} from "@mui/material";
import { Facebook, YouTube, Instagram, LinkedIn } from "@mui/icons-material";

import MemoryIcon from "@mui/icons-material/Memory";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import PeopleIcon from "@mui/icons-material/People";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
 import CourseLayout from "./CourseLayout";
import { List, ListItem } from "semantic-ui-react";
const ContentBox = styled("div")(({ theme }) => ({
  margin: "4%",
  [theme.breakpoints.down("sm")]: { margin: "16px" }
}));

const FooterContainer = styled(Box)(({ theme }) => ({
   // position: "fixed",
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "#2D2D2D", // Footer background color
    padding: "10px 20px",
    textAlign: "center",
    color: "#fff",
  }));
export default function Footer() {
  const theme = useTheme();



  return (
    <div>
      <FooterContainer>
      <Fragment>
      <ContentBox>
          <Grid container spacing={4}>
           
          <Grid item xs={12} md={4}>
              <img
              style={{  height: "60px" }}
              src="WhiteSpringBoardLogo.png"
          ></img>
         <br></br>
         <br></br>
         <br></br>
          <Typography style={{fontSize:'larger'}}variant="body2">
            500 Old Country Rd, Suite 304
          </Typography>
          <Typography style={{fontSize:'larger'}} variant="body2">Garden City, NY 11530</Typography>
          <Typography style={{fontSize:'larger'}} variant="body2">Phone: 516.414.2000</Typography>
        <br></br>
          <Box sx={{ marginTop: "10px" }}>
            <IconButton
              sx={{ color: "#FFF"  }}
              href="https://facebook.com"
              target="_blank"
            >
              <Facebook sx={{fontSize:32}}/>
            </IconButton>
            <IconButton
              sx={{ color: "#FFF" }}
              href="https://youtube.com"
              target="_blank"
            >
              <YouTube sx={{fontSize:32}}/>
            </IconButton>
            <IconButton
              sx={{ color: "#FFF" }}
              href="https://instagram.com"
              target="_blank"
            >
              <Instagram sx={{fontSize:32}}/>
            </IconButton>
            <IconButton
              sx={{ color: "#FFF" }}
              href="https://linkedin.com"
              target="_blank"
            >
              <LinkedIn sx={{fontSize:32}}/>
            </IconButton>
          </Box>
        </Grid>
              <Grid item xs={12} sm={5}>
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            Our Company
          </Typography>
          {/* <Typography variant="body2" sx={{ marginTop: "10px",fontSize:'inherit' ,textAlign:'justify'}}>
          We support over 500 students annually in our STEM Education Workshops, Workforce Development Courses and Technology Services. 
           We are changing the face of Technology by intentionally implementing a strategy that creates awareness, build pipelines, 
           and provide a clear path for each participant.   


          </Typography> */}
          <Box sx={{ marginTop: "20px",display: "flex", alignItems: "flex-start", mb: 1 }}>
          <Typography variant="body2" sx={{ marginTop: "10px",fontSize:'inherit' ,textAlign:'justify'}}>
          We support over 500 students annually in our STEM Education Workshops, Workforce Development Courses and Technology Services. 
           We are changing the face of Technology by intentionally implementing a strategy that creates awareness, build pipelines, 
           and provide a clear path for each participant.   


          </Typography>
    </Box>
          <br></br>
          <Typography variant="body2" sx={{ marginTop: "10px",fontSize:'inherit',textAlign:'justify' }}>
          Our diversity and inclusion imperatives are supported with key partnerships.
          </Typography>
              </Grid>

              {/* <Grid item xs={12} sm={4} >
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            Our Divisions
          </Typography>
          
          <Typography variant="body2" sx={{ marginTop: "10px",fontSize:'medium' }}>
            • Education Services
          </Typography>
          <Typography variant="body2" sx={{ fontSize:'medium' }}>• STEM Workshops</Typography>
          <Typography variant="body2" sx={{ fontSize:'medium' }}>• Entrepreneurship Workshops</Typography>
          <Typography variant="body2" sx={{ marginTop: "25px" ,fontSize:'medium'}}>
            • Technology Services
          </Typography>
          <Typography variant="body2" sx={{ fontSize:'medium' }}>• Data Science / Data Analytics</Typography>
          <Typography variant="body2" sx={{ fontSize:'medium' }}>• Software Engineering</Typography>
          <Typography variant="body2" sx={{ fontSize:'medium' }}>• Managed IT Services</Typography>
          <Typography variant="body2" sx={{ marginTop: "25px" ,fontSize:'medium'}}>
            • Workforce Development
          </Typography>
          <Typography variant="body2" sx={{ fontSize:'medium' }}>• Suffolk County Department of Labor</Typography>
          <Typography variant="body2" sx={{ fontSize:'medium' }}>• Hempstead Works - DOOR</Typography>
              </Grid> */}


<Grid item xs={12} sm={3}>
  <Typography variant="h6" sx={{ fontWeight: "bold" }}>
    Our Divisions
  </Typography>
  <Box sx={{ mt: 2 }}>
    {/* Grouped and aligned bullets */}
    <Box sx={{ display: "flex", alignItems: "flex-start", mb: 1 }}>
      <Typography sx={{ fontSize: "medium", minWidth: "20px", textAlign: "right" }}>•</Typography>
      <Typography sx={{ fontSize: "medium", pl: 1 }}>Education Services</Typography>
    </Box>
    <Box sx={{ display: "flex", alignItems: "flex-start", mb: 1 }}>
      <Typography sx={{ fontSize: "medium", minWidth: "20px", textAlign: "right" }}>•</Typography>
      <Typography sx={{ fontSize: "medium", pl: 1 }}>STEM Workshops</Typography>
    </Box>
    <Box sx={{ display: "flex", alignItems: "flex-start", mb: 2 }}>
      <Typography sx={{ fontSize: "medium", minWidth: "20px", textAlign: "right" }}>•</Typography>
      <Typography sx={{ fontSize: "medium", pl: 1 }}>Entrepreneurship Workshops</Typography>
    </Box>
    <Box sx={{ display: "flex", alignItems: "flex-start", mb: 1 }}>
      <Typography sx={{ fontSize: "medium", minWidth: "20px", textAlign: "right" }}>•</Typography>
      <Typography sx={{ fontSize: "medium", pl: 1 }}>Technology Services</Typography>
    </Box>
    <Box sx={{ display: "flex", alignItems: "flex-start", mb: 1 }}>
      <Typography sx={{ fontSize: "medium", minWidth: "20px", textAlign: "right" }}>•</Typography>
      <Typography sx={{ fontSize: "medium", pl: 1 }}>Data Science / Data Analytics</Typography>
    </Box>
    <Box sx={{ display: "flex", alignItems: "flex-start", mb: 1 }}>
      <Typography sx={{ fontSize: "medium", minWidth: "20px", textAlign: "right" }}>•</Typography>
      <Typography sx={{ fontSize: "medium", pl: 1 }}>Software Engineering</Typography>
    </Box>
    <Box sx={{ display: "flex", alignItems: "flex-start", mb: 2 }}>
      <Typography sx={{ fontSize: "medium", minWidth: "20px", textAlign: "right" }}>•</Typography>
      <Typography sx={{ fontSize: "medium", pl: 1 }}>Managed IT Services</Typography>
    </Box>
    <Box sx={{ display: "flex", alignItems: "flex-start", mb: 1 }}>
      <Typography sx={{ fontSize: "medium", minWidth: "20px", textAlign: "right" }}>•</Typography>
      <Typography sx={{ fontSize: "medium", pl: 1 }}>Workforce Development</Typography>
    </Box>
    <Box sx={{ display: "flex", alignItems: "flex-start", mb: 1 }}>
      <Typography sx={{ fontSize: "medium", minWidth: "20px", textAlign: "right" }}>•</Typography>
      <Typography sx={{ fontSize: "medium", pl: 1 }}>Suffolk County Department of Labor</Typography>
    </Box>
    <Box sx={{ display: "flex", alignItems: "flex-start" }}>
      <Typography sx={{ fontSize: "medium", minWidth: "20px", textAlign: "right" }}>•</Typography>
      <Typography sx={{ fontSize: "medium", pl: 1 }}>Hempstead Works - DOOR</Typography>
    </Box>
  </Box>
</Grid>

            </Grid>
        
         
      
      </ContentBox>
    </Fragment>
        {/* <Divider/>
    <p>Copyright © 2024 | Powered by SpringBoard Incubators Inc | All Rights Reserved</p> */}
    <Box
        sx={{
          borderTop: "1px solid #444",
          marginTop: "20px",
          paddingTop: "10px",
          textAlign: "center",
          
        }}
      >
        {/* <Typography variant="body2">
          SpringBoard Incubators is a 501(c)(3) organization located at 500 Old
          Country Rd, Suite 304 Garden City, NY 11530. Established in 2016.
        </Typography> */}
        <Typography variant="body2" sx={{ marginTop: "10px",fontSize:'medium' }}>
          Copyright © 2024 | Powered by{" "}
          <span style={{ color: "#4CAF50" }}>SpringBoard Incubators Inc</span> |
          All Rights Reserved
        </Typography>
      </Box>
  </FooterContainer>
    </div>
  );
}

